import React from 'react'

export default function FrontHeader() {
  return (
    <header className="header">
      <div className="header__container content-wrapper">
        <div className="site-header__content">
          <div className="site-header__mobile-wrapper">
            <div className="site-header__logo-wrapper">
              <div id="hs_cos_wrapper_header_logo" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <h3 className style={{marginTop: 35, color: '#00ffff'}}>Seiky</h3>
              </div>
            </div>
            <a href="javascript:void(0)" className="site-header__menu-toggle mobile-trigger">
              <span />
              <span />
            </a>
          </div>
          <nav className="custom-menu-primary">
            <span id="hs_cos_wrapper_my_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu">
              <div id="hs_menu_wrapper_my_menu" className="hs-menu-wrapper  hs-menu-flow-horizontal">
                <ul role="menu">
                  <li className role="none"><a href="#">Home</a>
                  </li>
                  <li className role="none"><a href="#">Advertisers</a>
                  </li>
                  <li className role="none"><a href="#">Publishers</a>
                  </li>
                  <li className role="none"><a href="#">Company</a>
                  </li>
                  <li className role="none"><a href="#">Join Us</a></li>
                </ul>
              </div>
            </span>
          </nav>
        </div>
      </div>
    </header>
  )
}
