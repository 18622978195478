import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';

export default function Contact() {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetching countries data from the API
    axios.get('https://restcountries.com/v3.1/all?fields=name,flags')
      .then(response => {
        const countryData = response.data.map(country => ({
          value: country.name.common,
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={country.flags.png}
                alt="flag"
                style={{ width: '20px', marginRight: '10px' }}
              />
              {country.name.common}
            </div>
          ),
        }));
        setCountries(countryData);
      })
      .catch(error => {
        console.error('Error fetching country data:', error);
      });
  }, []);

  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-7 bottom_area-row-2-background-layers dnd-section bottom_area-row-2-background-gradient">
      <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell dnd-column" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
          <div className="row-fluid-wrapper row-depth-1 row-number-8 dnd-row">
            <div className="row-fluid ">
              <div className="span12 widget-span widget-type-custom_widget dnd-module" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                <div id="hs_cos_wrapper_widget_1637045874885" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <div id="newsletter" className="HsFormsModules_wrapper">
                    <div className="HsFormsModule_container">
                      <div className="HsFormsModule_content">
                        <div className="HsFormsModule-inner-wrapper">
                          <div className="HsFormsModule-inner-content-part">
                            <div className="performance-content-wrapper footer-form">
                              <h2 style={{ color: '#fff' }}>GET In Touch</h2>
                              <div className="form-container">
                                <div className="form-header">CONTACT OUR TEAM</div>
                                <form>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <label htmlFor="first-name">First Name*</label>
                                      <input type="text" id="first-name" name="first-name" placeholder="Enter your first name" required />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="last-name">Last Name*</label>
                                      <input type="text" id="last-name" name="last-name" placeholder="Enter your last name" required />
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <label htmlFor="email">Email*</label>
                                      <input type="email" id="email" name="email" placeholder="Enter your email" required />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="company">Company*</label>
                                      <input type="text" id="company" name="company" placeholder="Enter your company name" required />
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <label htmlFor="country">Country*</label>
                                      <Select
                                        id="country"
                                        options={countries}
                                        placeholder="Select your country"
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            backgroundColor: '#00ffff',
                                            border: '1px solid #01153a',
                                            color: '#01153a',
                                          }),
                                          menu: (base) => ({
                                            ...base,
                                            backgroundColor: '#01153a',
                                          }),
                                          option: (base, state) => ({
                                            ...base,
                                            color: state.isFocused ? '#00ffff' : '#fff',
                                            backgroundColor: state.isFocused ? '#01153a' : '#01153a',
                                          }),
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="i-am">I am...</label>
                                      <Select
                                        id="i-am"
                                        options={[
                                            { value: 'ssp', label: 'SSP' },
                                            { value: 'dsp', label: 'DSP' },
                                            { value: 'exchange', label: 'Exchange' },
                                            { value: 'brand', label: 'Brand' },
                                            { value: 'agency', label: 'Agency' },
                                            { value: 'game-publisher', label: 'Game Publisher' },
                                            { value: 'developer', label: 'Game Developer' },
                                            { value: 'vendor', label: 'Tech Vendor' },
                                            { value: 'press', label: 'Press' },
                                            { value: 'reseller', label: 'Reseller (Demand)' },
                                            { value: 'other', label: 'Other' },
                                        ]}
                                        placeholder="Select"
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            backgroundColor: '#00ffff',
                                            border: '1px solid #01153a',
                                            color: '#01153a',
                                          }),
                                          menu: (base) => ({
                                            ...base,
                                            backgroundColor: '#01153a',
                                          }),
                                          option: (base, state) => ({
                                            ...base,
                                            color: state.isFocused ? '#00ffff' : '#fff',
                                            backgroundColor: state.isFocused ? '#01153a' : '#01153a',
                                          }),
                                        }}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <label htmlFor="job-function">Job Function</label>
                                      <Select
                                        id="job-function"
                                        options={[
                                            { value: 'admin', label: 'Administrative (HR, Legal, Finance)' },
                                            { value: 'business-development', label: 'Business Development & Sales' },
                                            { value: 'marketing', label: 'Marketing' },
                                            { value: 'operations', label: 'Operations / Strategy' },
                                            { value: 'engineering', label: 'Product & Engineering' },
                                            { value: 'research', label: 'Research & Analytics' },
                                            { value: 'customer-service', label: 'Customer Service & Account Management' },
                                            { value: 'monetization', label: 'Game Monetization' },
                                            { value: 'other', label: 'Other' },
                                        ]}
                                        placeholder="Select"
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            backgroundColor: '#00ffff',
                                            border: '1px solid #01153a',
                                            color: '#01153a',
                                          }),
                                          menu: (base) => ({
                                            ...base,
                                            backgroundColor: '#01153a',
                                          }),
                                          option: (base, state) => ({
                                            ...base,
                                            color: state.isFocused ? '#00ffff' : '#fff',
                                            backgroundColor: state.isFocused ? '#01153a' : '#01153a',
                                          }),
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="job-seniority">Job Seniority</label>
                                      <Select
                                        id="job-seniority"
                                        options={[
                                          { value: 'junior', label: 'Junior' },
                                          { value: 'senior', label: 'Senior' },
                                          { value: 'lead', label: 'Lead' },
                                        ]}
                                        placeholder="Select"
                                        styles={{
                                          control: (base) => ({
                                            ...base,
                                            backgroundColor: '#00ffff',
                                            border: '1px solid #01153a',
                                            color: '#01153a',
                                          }),
                                          menu: (base) => ({
                                            ...base,
                                            backgroundColor: '#01153a',
                                          }),
                                          option: (base, state) => ({
                                            ...base,
                                            color: state.isFocused ? '#00ffff' : '#fff',
                                            backgroundColor: state.isFocused ? '#01153a' : '#01153a',
                                          }),
                                        }}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group" style={{ flex: 1 }}>
                                      <label htmlFor="inquiry">What does your inquiry relate to?</label>
                                      <textarea id="inquiry" name="inquiry" rows={3} placeholder="Enter your inquiry" defaultValue={""} style={{ backgroundColor: '#00ffff', border: '1px solid #01153a', color: '#01153a' }} />
                                    </div>
                                  </div>
                                  <div className="form-submit">
                                    <button type="submit">Submit</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/*end widget-span */}
            </div>{/*end row*/}
          </div>{/*end row-wrapper */}
        </div>{/*end widget-span */}
      </div>{/*end row*/}
    </div>
  );
}
